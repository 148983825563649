import Compressor from 'compressorjs';
import EXIF from 'exif-js';

//@ts-ignore
const S3Upload = require('react-s3-uploader/s3upload');

export const S3_PATH_PREFIX = 'user-media/';
const S3_PREFIX_REGEX = new RegExp(
  `^${S3_PATH_PREFIX}[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}_`,
  'i',
);

export interface PreviewableFile extends File {
  preview: string;
  type: string; // mime (aka content) type
  exifData: any;
}

export type S3UploadResult = {
  fileKey: string;
  filename: string;
  publicUrl: string;
  signedUrl: string;
};

export type UploadMediaToS3 = {
  files: File[];
  serverEndpoint: string;
  onBatchFinished: (data: S3UploadResult, file: File) => void;
  onPreprocessComplete?: (file: PreviewableFile) => void;
  setProgress?: (p: number) => void;

  /**
   * @see {@link https://github.com/odysseyscience/react-s3-uploader/blob/4faf574977f4123a9040602bdc38476cfb216e97/s3upload.js#L163-L167 | `react-s3-uploader`}
   */
  onError?: (
    message: string,
    file?: string,
    xhr?: {
      response: string;
      status: number;
      statusText: string;
      readyState: number;
    },
  ) => void;

  mode?: string;
};

export const fileNameFromS3Path = (path: string) => {
  /**
   * NB: this depends on an internal detail of how react-s3-uploader modifies a filename
   * when generating an s3 path. It takes our s3 path prefix and suffixes that with a uuid
   * before prepending to the filename. For example, "cats.png" is converted to something
   * like "user-media/1d476299-60b5-4d5d-a46a-79ec06339df5_cats.png"
   */
  return path.replace(S3_PREFIX_REGEX, '');
};

export const uploadMediaToS3 = ({
  files,
  serverEndpoint,
  onPreprocessComplete,
  setProgress,
  onError,
  onBatchFinished,
  mode,
}: UploadMediaToS3) => {
  // eslint-disable-next-line no-new
  const progressByFile = new Map<string, number>();
  const totalPossibleProgress = 100 * files.length;

  const onProgress = (percent: number, message: string, file: File) => {
    if (!setProgress) {
      return;
    }
    progressByFile.set(file.name, percent);
    const totalProgress = [...progressByFile.values()].reduce(
      (acc, val) => acc + val,
      0,
    );
    setProgress(totalProgress / totalPossibleProgress);
  };

  new S3Upload({
    files,
    signingUrl: '/uploads/sign',
    uploadRequestHeaders: { 'x-amz-acl': 'private' },
    onFinishS3Put: onBatchFinished,
    onProgress,
    onError,
    contentDisposition: 'auto',
    server: serverEndpoint,
    scrubFilename: (filename: string) => {
      const fn = filename.replace(/([^\w\d_\-.]+|--)/gi, '');
      const name = fn.substring(0, fn.lastIndexOf('.'));
      const ext = fn.substring(fn.lastIndexOf('.'));
      return `${name}${ext.toLowerCase()}`;
    },
    s3path: S3_PATH_PREFIX,
    getSignedUrl(file: File, cb: (result: { signedUrl: string }) => void) {
      // Use s3 accelerate
      this.executeOnSignedUrl(file, (result: { signedUrl: string }) => {
        result.signedUrl = result.signedUrl.replace(
          /s3\.amazonaws\./,
          's3-accelerate.amazonaws.',
        );
        cb(result);
      });
    },
    preprocess: (file: File, next: (file: File) => void) =>
      preprocess(
        file,
        next,
        onPreprocessComplete || (() => {}),
        onError || (() => {}),
        mode,
      ),
  });
};

const preprocess = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  onError: (e: string) => void,
  mode?: string,
) => {
  if (mode === 'video' || file.type.startsWith('video/')) {
    preprocessVideo(file, next, onPreprocessComplete, onError);
    return;
  }

  if (mode === 'image' || file.type.startsWith('image/')) {
    preprocessImage(file, next, onPreprocessComplete, onError);
    return;
  }

  preprocessUnknown(file, next, onPreprocessComplete, onError);
};

const preprocessVideo = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError: (e: string) => void,
) => {
  // in javascript the File built-in object is non-enumerable so instead of spreading we are simply adding the fields we want
  const preProcessedFile = file as PreviewableFile;
  preProcessedFile.preview = URL.createObjectURL(file);
  onPreprocessComplete(preProcessedFile);
  next(file);
};

const preprocessImage = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  onError: (e: string) => void,
) => {
  // Exif data
  EXIF.getData(file as any, () => {
    if (file && file.type) {
      if (!file.type.match(/image[/]/)) {
        return onError('Please upload an image file.');
      }

      if (file.type.match(/image[/]heic/)) {
        return onError(
          'Please convert this file to a JPEG file and try again.',
        );
      }
    }

    // window.EXIF = EXIF;
    const tags = EXIF.getAllTags(file);
    if (tags && tags.MakerNote) {
      delete tags.MakerNote;
    }

    // Compression
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,

      success(result) {
        const preProcessedFile = {
          name: file.name,
          type: file.type,
          preview: URL.createObjectURL(result),
          exifData: tags,
        } as PreviewableFile;
        onPreprocessComplete(preProcessedFile);
        next(result as File);
      },
      error(e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (file && file.type) {
          if (!file.type.match(/image[/]/)) {
            return onError('Please upload an image file.');
          }
          if (file.type.match(/image[/]heic/)) {
            return onError(
              'Please convert this file to a JPEG file and try again.',
            );
          }
        }
        onError('There was an error processing your image.');
      },
    });
  });
};

// For unknown/unhandled file types, use a "cannot generate preview" thumb
const preprocessUnknown = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError: (e: string) => void,
) => {
  // in javascript the File built-in object is non-enumerable so instead of spreading we are simply adding the fields we want
  const previewFile = file as PreviewableFile;
  previewFile.preview = '';
  previewFile.exifData = '';

  onPreprocessComplete(previewFile);
  next(file);
};
